import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TbAlertTriangleFilled } from "react-icons/tb";
import io from "socket.io-client";
import useStore from "../store";
import axios from "axios";

const socket = io(process.env.REACT_APP_API_BASE_URL);

const EmergencyAlertPopup = ({
  openAlertPopup,
  setOpenAlertPopup,
  currentUserId,
  currentUserName,
}) => {
  const [alert, setAlert] = useState(null);
  const timerRef = useRef(null); // Reference to the timeout
  const setEmergencyPopupState = useStore(
    (state) => state.setEmergencyPopupState
  );

  useEffect(() => {
    const fetchLatestAlert = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/server/alert/latest-alert`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAlert(data);
      } catch (error) {
        console.error("Failed to fetch latest alert", error);
      }
    };

    fetchLatestAlert();

    socket.on("latest-alert", (latestAlert) => {
      setAlert(latestAlert);
      setEmergencyPopupState(true);

      // Clear any existing timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set timeout to automatically close the popup after 5 seconds
      timerRef.current = setTimeout(() => {
        setOpenAlertPopup(false); // Close the popup after 15 seconds
      }, 15000);
    });

    return () => {
      socket.off("latest-alert");
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [setEmergencyPopupState]);

  const handleOk = async () => {
    if (!alert) return; // Ensure there is an alert to read

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/server/notif/read`,
        {
          alertId: alert._id, // Include alert ID
          userId: currentUserId,
          userName: currentUserName,
        }
      );
      // Optionally, close the popup after saving
      setOpenAlertPopup(false);
    } catch (error) {
      console.error("Failed to save read status", error);
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const dynamicPopupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 2,
    width: "auto",
    width: "min(90%, 600px)",
    maxHeight: isMobile ? "95vh" : "calc(100vh - 100px)",
    overflowY: "auto",
    outline: "none",
  };

  return (
    <div>
      <Modal open={openAlertPopup} onClose={handleOk}>
        <Box
          className="bg-white rounded-xl border-2 border-red-400"
          sx={dynamicPopupStyle}
        >
          <div className="flex flex-col justify-center items-center px-1">
            <div>
              <TbAlertTriangleFilled
                className="text-red-500 animate-pulse"
                size={40}
              />
            </div>
            <div className="text-lg font-semibold bg-red-50 text-red-500 p-2 rounded-lg mt-2">
              EMERGENCY ALERT
            </div>
            {alert && (
              <div className="text-justify text-base mt-4 text-gray-600">
                <p>
                  <strong>Title:</strong> {alert.title}
                </p>
                <p>
                  <strong>Message:</strong> {alert.description}
                </p>
                <p>
                  <strong>Type:</strong> {alert.type}
                </p>
                <p>
                  <strong>Level:</strong> {alert.level}
                </p>
                <p>
                  <strong>Date and Time:</strong>{" "}
                  {new Date(alert.timestamp).toLocaleString()}
                </p>
              </div>
            )}
            <button
              onClick={handleOk}
              className="p-2 mt-8 bg-gray-100 rounded-md w-full font-semibold text-slate-500"
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EmergencyAlertPopup;
