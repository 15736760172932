import { Divider } from "@mui/joy";
import React from "react";

const TermsAndCondition = () => {
  return (
    <div>
      <div className="uppercase text-2xl font-semibold">
        Terms and Conditions
      </div>
      <p className="text-sm font-semibold text-gray-600 mb-4">
        Last update on October 2024
      </p>
      <Divider />
      <div className="my-4 text-justify">
        {/* Terms and conditions text */}
        <p className="font-semibold">AGREEMENT TO OUR LEGAL TERMS</p>
        <p className="mt-2 text-sm">
          We are Group 103 of smU 1, the owners of the app RESPO.
        </p>
        <p className="mt-2 text-sm">
          We operate the app "RESPO" (https://respo.smu1.com), along with any
          other related products and services that reference or link to these
          legal terms (the “Legal Terms”), which include these Terms and
          Conditions, our Privacy Policy, and any other guidelines or policies
          that may be provided by us from time to time (collectively referred to
          as the “Services”).
        </p>
        <p className="mt-2 text-sm">
          You can contact us by phone at 09911044732, email at respo@gmail.com.
        </p>
        <p className="mt-2 text-sm">
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity ("you"), and,
          concerning your access to and use of the Services. You agree that by
          accessing the Services, you have read, understood, and agreed to be
          bound by all of these Legal Terms.
        </p>
        <p className="mt-2 text-sm font-semibold">
          IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
          EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
          USE IMMEDIATELY.
        </p>
        <p className="mt-2 text-sm">
          Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated
          herein by reference. We reserve the right, in our sole discretion, to
          make changes or modifications to these Legal Terms at any time and for
          any reason. We will alert you about any changes by updating the "Last
          updated" date of these Legal Terms, and you waive any right to receive
          specific notice of each such change. It is your responsibility to
          periodically review these Legal Terms to stay informed of updates. You
          will be subject to, and will be deemed to have been made aware of and
          to have accepted, the changes in any revised Legal Terms by your
          continued use of the Services after the date such revised Legal Terms
          are posted. We recommend that you print a copy of these Legal Terms
          for your records.
        </p>
        <div className="mt-4 text-sm">
          <h2 className="text-sm font-semibold">TABLE OF CONTENTS</h2>
          <ol className="list-decimal list-inside">
            <li>OUR SERVICES</li>
            <li>INTELLECTUAL PROPERTY RIGHTS</li>
            <li>USER REPRESENTATIONS</li>
            <li>PROHIBITED ACTIVITIES</li>
            <li>USER GENERATED CONTRIBUTIONS</li>
            <li>CONTRIBUTION LICENSE</li>
            <li>SERVICES MANAGEMENT</li>
            <li>TERM AND TERMINATION</li>
            <li>MODIFICATIONS AND INTERRUPTIONS</li>
            <li>GOVERNING LAW</li>
            <li>DISPUTE RESOLUTION</li>
            <li>CORRECTIONS</li>
            <li>DISCLAIMER</li>
            <li>LIMITATIONS OF LIABILITY</li>
            <li>INDEMNIFICATION</li>
            <li>USER DATA</li>
            <li>ELECTRONIC COMMUNICATIONS</li>
            <li>MISCELLANEOUS</li>
            <li>CONTACT US</li>
          </ol>

          <h3 className="mt-4 text-sm font-semibold">1. OUR SERVICES</h3>
          <p>
            The information provided through the Services is not intended for
            use in jurisdictions where such distribution would be contrary to
            law or require registration. Users accessing the Services from other
            locations do so at their own risk and are responsible for compliance
            with local laws.
          </p>

          <h3 className="mt-4 text-sm font-semibold">
            2. INTELLECTUAL PROPERTY RIGHTS
          </h3>
          <p>
            We own or license all intellectual property rights in our Services,
            including all Content and Marks. The Content is protected by
            copyright and trademark laws. You may access and use the Services
            and download Content for personal, non-commercial use only.
            Unauthorized use of our Content is prohibited without prior written
            permission.
          </p>

          <h3 className="mt-4 text-sm font-semibold">
            3. USER REPRESENTATIONS
          </h3>
          <p>
            By using the Services, you represent that you have the legal
            capacity to comply with these Legal Terms, are not a minor, will not
            use the Services for illegal purposes, and will provide accurate
            information. We may suspend or terminate your account for any
            violations.
          </p>
        </div>
        <div className="text-sm">
          <h2 className="mt-4 text-sm font-semibold">
            4. PROHIBITED ACTIVITIES
          </h2>
          <p>
            You agree not to use the Services for any unauthorized purposes,
            including:
          </p>
          <ul className="list-disc pl-5">
            <li>
              Retrieving data to create collections or datasms without
              permission.
            </li>
            <li>
              Misleading users or attempting to obtain sensitive information.
            </li>
            <li>
              Interfering with security features or using the Services in
              violation of laws.
            </li>
            <li>
              Using information from the Services to harass or harm others.
            </li>
            <li>Submitting false reports or abusing support services.</li>
            <li>
              Uploading harmful materials or using automated systems for data
              extraction.
            </li>
            <li>Impersonating other users or modifying proprietary notices.</li>
            <li>
              Engaging in disruptive behavior or creating undue burdens on the
              Services.
            </li>
            <li>
              Attempting to bypass security measures or reverse-engineering the
              Services.
            </li>
            <li>Collecting user information for unsolicited communications.</li>
          </ul>
          <h2 className="mt-4 text-sm font-semibold">
            5. USER GENERATED CONTRIBUTIONS
          </h2>
          <p>
            You may create or submit content ("Contributions") to the Services.
            By doing so, you represent that you own or have the rights to your
            Contributions.
          </p>
          <h2 className="mt-4 text-sm font-semibold">
            6. CONTRIBUTION LICENSE
          </h2>
          <p>
            We may access and use your Contributions. You retain ownership and
            we are not liable for any statements in your Contributions.
          </p>
          <h2 className="mt-4 text-sm font-semibold">7. SERVICES MANAGEMENT</h2>
          <p>
            We reserve the right to monitor and manage the Services, including
            removing inappropriate content.
          </p>
          <h2 className="mt-4 text-sm font-semibold">
            8. TERM AND TERMINATION
          </h2>
          <p>
            These Terms remain in effect while you use the Services. We can deny
            access or terminate your account at our discretion.
          </p>
          <h2 className="mt-4 text-sm font-semibold">
            9. MODIFICATIONS AND INTERRUPTIONS
          </h2>
          <p>
            We may modify or discontinue the Services at any time without
            notice. We are not liable for interruptions or downtime.
          </p>
          <h2 className="mt-4 text-sm font-semibold">10. GOVERNING LAW</h2>
          <p>
            These Terms are governed by the laws of [Your Jurisdiction]. Any
            disputes will be resolved under these laws.
          </p>
        </div>

        <div class="text-sm">
          <h2 className="mt-4 text-sm font-semibold">11. DISPUTE RESOLUTION</h2>
          <h3 class="font-semibold">Informal Negotiations</h3>
          To expedite resolution of any dispute (each a "Dispute") between you
          and us (collectively, the "Parties"), we agree to negotiate informally
          for at least [number] days before pursuing arbitration. This process
          begins upon written notice from one Party to the other.
          <h3 class="font-semibold">Binding Arbitration</h3>
          Any Dispute shall be resolved by the International Commercial
          Arbitration Council under the European Arbitration Chamber in
          Brussels, Besmium, according to its rules. The arbitration will be
          limited to the specific Dispute between the Parties; class-action
          procedures are not allowed.
          <h3 class="font-semibold">Exceptions</h3>
          The following Disputes are exempt from arbitration: (a) disputes
          concerning intellectual property rights; (b) allegations of theft or
          unauthorized use; and (c) claims for injunctive relief. If this
          provision is found unlawful, such Disputes will be decided by a
          competent court.
          <h2 className="mt-4 text-sm font-semibold">12. CORRECTIONS</h2>
          The Services may contain inaccuracies. We reserve the right to correct
          any errors and update information without prior notice.
          <h2 className="mt-4 text-sm font-semibold">13. DISCLAIMER</h2>
          The Services are provided "as-is." Your use is at your own risk. We
          disclaim all warranties, including accuracy or completeness of
          content. We are not liable for any errors or damages resulting from
          your use of the Services.
          <h2 className="mt-4 text-sm font-semibold">
            14. LIMITATIONS OF LIABILITY
          </h2>
          We are not liable for any indirect, consequential, or punitive
          damages, even if we were advised of such possibilities. Our liability
          is limited to the amount you paid us, if any.
          <h2 className="mt-4 text-sm font-semibold">15. INDEMNIFICATION</h2>
          You agree to indemnify us against any claims arising from your use of
          the Services or breach of these terms.
          <h2 className="mt-4 text-sm font-semibold">16. USER DATA</h2>
          We maintain certain data for managing the Services, but you are
          responsible for all data you transmit.
          <h2 className="mt-4 text-sm font-semibold">
            17. ELECTRONIC COMMUNICATIONS
          </h2>
          By using the Services, you consent to electronic communications and
          signatures.
          <h2 className="mt-4 text-sm font-semibold">18. MISCELLANEOUS</h2>
          These Legal Terms represent the entire agreement. We may assign our
          rights at any time, and if any part is found unenforceable, the rest
          remains valid.
          <h2 className="mt-4 text-sm font-semibold">19. CONTACT US</h2>
          For complaints or further information, please contact us at
          respo@gmail.com
        </div>

        {/* Terms and conditions text */}
        <div className="uppercase text-2xl font-semibold mt-8">
          Privacy Policy
        </div>
        <p className="text-sm font-semibold text-gray-600 mb-4">
          Last update on October 2024
        </p>
        <Divider />
        <div className="text-sm mt-2">
          <p className="text-sm mb-4">
            This Privacy Notice describes how we process your personal
            information when using our services ("Services"), including when
            you:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>
              Download and use our mobile app (Respo) or related applications.
            </li>
            <li>Engage with us through sales, marketing, or events.</li>
          </ul>
          <p className="text-sm mb-4">
            Questions? This notice helps you understand your privacy rights. We
            manage your personal information processing. If you disagree with
            our practices, please do not use our Services. For inquiries,
            contact us at respo@gmail.com.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            Key Points Summary
          </h2>
          <p className="text-sm mb-4">
            Below are key points from our Privacy Notice. Click the links for
            more details.
          </p>

          <h3 className="font-semibold mb-1">
            What personal information do we process?
          </h3>
          <p className="text-sm mb-4">
            We may process personal information ssm on your interactions with
            our Services. Learn more about what you disclose to us.
          </p>

          <h3 className="font-semibold mb-1">
            Do we process any sensitive personal information?
          </h3>
          <p className="text-sm mb-4">
            We do not process sensitive personal information such as racial or
            ethnic origins, sexual orientation, or religious beliefs.
          </p>

          <h3 className="font-semibold mb-1">
            Do we collect any information from third parties?
          </h3>
          <p className="text-sm mb-4">
            We may collect information from public datasms, marketing partners,
            social media, and other sources.
          </p>

          <h3 className="font-semibold mb-1">
            How do we process your information?
          </h3>
          <p className="text-sm mb-4">
            We process your information to improve our Services, communicate
            with you, prevent fraud, and comply with laws.
          </p>

          <h3 className="font-semibold mb-1">
            In what situations and with which parties do we share personal
            information?
          </h3>
          <p className="text-sm mb-4">
            We share information in specific situations with designated third
            parties.
          </p>

          <h3 className="font-semibold mb-1">
            How do we keep your information safe?
          </h3>
          <p className="text-sm mb-4">
            We implement robust measures to protect your information but cannot
            guarantee absolute security against unauthorized access.
          </p>

          <h3 className="font-semibold mb-1">What are your rights?</h3>
          <p className="text-sm mb-4">
            Your privacy rights vary by location ssm on applicable laws.
          </p>

          <h3 className="font-semibold mb-1">
            How do you exercise your rights?
          </h3>
          <p className="text-sm mb-4">
            Submit a data subject access request or contact us to exercise your
            rights.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">Table of Contents</h2>
          <ol className="list-decimal pl-6 mb-4">
            <li>What Information Do We Collect?</li>
            <li>How Do We Process Your Information?</li>
            <li>When and With Whom Do We Share Your Personal Information?</li>
            <li>Do We Offer Artificial Intelligence-ssm Products?</li>
            <li>How Long Do We Keep Your Information?</li>
            <li>How Do We Keep Your Information Safe?</li>
            <li>What Are Your Privacy Rights?</li>
            <li>Controls for Do-Not-Track Features</li>
            <li>Do We Make Updates to This Notice?</li>
            <li>How Can You Contact Us About This Notice?</li>
            <li>
              How Can You Review, Update, or Delete the Data We Collect From
              You?
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-sm font-semibold">
            1. WHAT INFORMATION DO WE COLLECT?
          </h2>
          <p className="text-sm font-semibold">Personal Information:</p>
          <p className="text-sm">
            We collect personal information you voluntarily provide when
            registering, inquiring about our services, or contacting us. This
            may include:
          </p>
          <ul className="list-disc pl-5 text-sm">
            <li>Names</li>
            <li>Phone Numbers</li>
            <li>Email Addresses</li>
            <li>Mailing Addresses</li>
            <li>Usernames</li>
            <li>Passwords</li>
            <li>Contact Preferences</li>
          </ul>

          <p className="text-sm font-semibold">Sensitive Information:</p>
          <p className="text-sm">We do not process sensitive information.</p>

          <p className="text-sm font-semibold">Application Data:</p>
          <p className="text-sm">If you use our apps, we may collect:</p>
          <ul className="list-disc pl-5 text-sm">
            <li>
              Geolocation Information: Optional access to your device's
              location.
            </li>
            <li>
              Push Notifications: Permission to send updates about your account.
            </li>
          </ul>

          <p className="text-sm">
            This data helps us maintain security and analyze usage.
          </p>

          <h2 className="text-sm font-semibold mt-4">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h2>
          <p className="text-sm">We process your information to:</p>
          <ul className="list-disc pl-5 text-sm">
            <li>Manage user accounts.</li>
            <li>Deliver services.</li>
            <li>Send administrative updates.</li>
            <li>Comply with legal obligations.</li>
          </ul>

          <h2 className="text-sm font-semibold mt-4">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h2>
          <p className="text-sm">
            We may share your information in the following situations:
          </p>
          <ul className="list-disc pl-5 text-sm">
            <li>Business Transfers: During mergers or acquisitions.</li>
          </ul>

          <h2 className="text-sm font-semibold mt-4">
            4. DO WE OFFER AI-BASED PRODUCTS?
          </h2>
          <p className="text-sm">
            Yes, we provide AI products to enhance your experience. Your data
            will be shared with AI Service Providers to enable these features.
          </p>

          <h2 className="text-sm font-semibold mt-4">
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h2>
          <p className="text-sm">
            We retain your personal information only as long as necessary for
            the purposes outlined or as required by law.
          </p>

          <h2 className="text-sm font-semibold mt-4">
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </h2>
          <p className="text-sm">
            We implement reasonable security measures to protect your
            information. However, no method is 100% secure, and you access our
            services at your own risk.
          </p>
        </div>

        <div>
          <h2 className="text-sm font-semibold mb-2 mt-4">
            7. WHAT ARE YOUR PRIVACY RIGHTS?
          </h2>
          <p className="text-sm font-semibold">
            You can review, change, or terminate your account anytime based on
            your location.
          </p>
          <p className="text-sm ">
            If we process your personal information based on consent, you can
            withdraw it anytime by contacting us through the details in the "HOW
            CAN YOU CONTACT US ABOUT THIS NOTICE?" section. This won't affect
            the legality of prior processing.
          </p>

          <h3 className="text-sm font-semibold mt-4">Account Information</h3>
          <p className="text-sm">
            To review or change your account info, log in to your settings. Upon
            your request to terminate your account, we'll deactivate or delete
            it but may retain some data to prevent fraud and comply with legal
            requirements.
          </p>
          <p className="text-sm ">
            For questions about your privacy rights, email us at respo@gmail.com
          </p>

          <h2 className="text-sm font-semibold mb-2 mt-4">
            8. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h2>
          <p className="text-sm ">
            Most browsers include a Do-Not-Track ("DNT") feature. Currently, we
            do not respond to DNT signals but will notify you if we adopt a
            standard for online tracking in the future.
          </p>

          <h2 className="text-sm font-semibold mb-2 mt-4">
            9. DO WE MAKE UPDATES TO THIS NOTICE?
          </h2>
          <p className="text-sm ">
            Yes, we update this notice to comply with laws. Updated versions
            will have a new "Revised" date. We may notify you of significant
            changes via notice or direct communication.
          </p>

          <h2 className="text-sm font-semibold mb-2 mt-4">
            10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h2>
          <p className="text-sm ">
            For questions or comments, email us at respo@gmail.com or contact us
            by text at 09911044732
          </p>

          <h2 className="text-sm font-semibold mb-2 mt-4">
            11. HOW CAN YOU REVIEW, UPDATE, OR DELETE YOUR DATA?
          </h2>
          <p className="text-sm ">
            You can request access to, correct, or delete your personal
            information. To initiate this, please fill out and submit a data
            subject access request. Your rights may be limited by applicable
            law.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
