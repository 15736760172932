import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import AIChat from "../../assets/3D_AI_Chatbot.png";
import { PiFirstAidKitBold } from "react-icons/pi";
import { MdOutlineCameraAlt } from "react-icons/md";
import { ButtonLoader } from "../../components/Loader";
import { IoMdSend, IoMdClose } from "react-icons/io";
import Upload from "../../components/AIChatBot/Upload";
import { IKImage } from "imagekitio-react";
import ChatBubbleWing from "../../assets/ChatBubbleWing";
import useStore from "../../store";
import { FaPersonShelter } from "react-icons/fa6";

const AIChats = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const { currentUser } = useStore((state) => ({
    currentUser: state.currentUser,
  }));
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef = useRef(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (text) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/server/chat/newchat`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({ text }),
        }
      );

      const result = await response.json();
      return result.chatId;
    },
    onSuccess: (chatId) => {
      queryClient.invalidateQueries({ queryKey: ["userChats"] });
      if (chatId) {
        navigate(`/ai-chats/chat/${chatId}`);
      } else {
        console.error("Chat ID is undefined");
      }
    },
  });

  const handlePromptClick = (text) => {
    mutation.mutate(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!text.trim() || isLoading) return;

    setIsLoading(true); // Start loading
    try {
      // Simulate API call or mutation
      await mutation.mutateAsync(text);
      setText(""); // Clear input field after submission
    } catch (error) {
      console.error("Error submitting text:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      const textArea = textAreaRef.current;
      textArea.style.height = "auto";
      textArea.style.height = `${Math.min(textArea.scrollHeight, 240)}px`;
    }
  }, [text]);

  useEffect(() => {
    setIsDisabled(!text.trim() || isLoading);
  }, [text, isLoading]);

  const handleKeyDown = (e) => {
    if (isLoading) {
      e.preventDefault(); // Prevent input when loading
      return;
    }

    if (e.key === "Enter" && !e.shiftKey && text.trim()) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-140px)]  w-full max-w-lg">
      <div className="mt-6 flex flex-col justify-center items-center flex-1 overflow-auto">
        <div className="text-center text-lg mb-3">
          <div>
            Hi!{" "}
            <span className="font-bold bg-gradient-to-r from-blue-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% inline-block text-transparent bg-clip-text">
              {currentUser.name}
            </span>
          </div>
          <div> How can I help you today?</div>
        </div>
        <div className="relative z-[996]">
          <img
            src={AIChat}
            className="object-cover w-[13rem] md:w-[20rem] select-none"
          />
        </div>
        <div className="w-full h-80 overflow-auto px-6 no-scrollbar">
          <div
            onClick={() =>
              handlePromptClick("Find the Nearest Evacution Center")
            }
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center mb-2 text-sm cursor-pointer select-none"
          >
            <div>Find the Nearest Evacution Center</div>
          </div>
          <div
            onClick={() =>
              handlePromptClick(
                "What essential items should I include in my emergency kit?"
              )
            }
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center text-center mb-2 text-sm cursor-pointer select-none"
          >
            <div>
              What essential items should I include in my emergency kit?
            </div>
          </div>
          <div
            onClick={() => handlePromptClick("Evacuation Guidelines for flood")}
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center  mb-2 text-sm cursor-pointer select-none"
          >
            <div>Evacuation Guidelines for flood</div>
          </div>
          <div
            onClick={() => handlePromptClick("How to Perform CPR")}
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center mb-2 text-sm cursor-pointer select-none"
          >
            <div>How to Perform CPR</div>
          </div>
          <div
            onClick={() => handlePromptClick("What to Do After an Earthquake?")}
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center mb-2 text-sm cursor-pointer select-none"
          >
            <div>What to Do After an Earthquake?</div>
          </div>
          <div
            onClick={() =>
              handlePromptClick("First Aid for Wounds and Injuries")
            }
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center mb-2 text-sm cursor-pointer select-none"
          >
            <div>First Aid for Wounds and Injuries</div>
          </div>
          <div
            onClick={() => handlePromptClick("Prepare for a Natural Disaster")}
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center mb-2 text-sm cursor-pointer select-none"
          >
            <div>Prepare for a Natural Disaster</div>
          </div>
          <div
            onClick={() => handlePromptClick("What to Do in a Power Outage?")}
            className="bg-blue-500 bg-opacity-15 text-blue-500 border border-blue-300 p-3 rounded-xl w-full flex gap-2 items-center justify-center mb-2 text-sm cursor-pointer select-none"
          >
            <div>What to Do in a Power Outage?</div>
          </div>
        </div>
      </div>

      <form className="w-full px-3 relative" onSubmit={handleSubmit}>
        <textarea
          ref={textAreaRef}
          autoComplete="off"
          name="text"
          rows={1}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Ask me anything..."
          className="w-full rounded-2xl text-text dark:text-text_dark dark:bg-secondary-dark-color pl-4 pr-14 py-3 outline-none resize-none"
          style={{
            overflowY:
              textAreaRef.current?.scrollHeight > 240 ? "auto" : "hidden",
          }}
          disabled={isLoading} // Disable input when loading
        />
        <button
          type="submit"
          className={`absolute right-6 bottom-[0.725rem] p-2 rounded-full text-white dark:text-text_dark ${
            isDisabled
              ? "bg-gray-400 dark:bg-secondary-surface-color"
              : "bg-blue-500"
          }`}
          disabled={isDisabled}
        >
          {isLoading ? (
            <div className="w-5 h-5 ">
              <ButtonLoader color="white" />
            </div> // Replace with your loader
          ) : (
            <IoMdSend size={20} />
          )}
        </button>
      </form>
    </div>
  );
};

export default AIChats;
