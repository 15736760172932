import { useEffect, useRef, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import profile from "../assets/profile_img.png";
import { HiBellAlert } from "react-icons/hi2";
import { FaAmbulance, FaStreetView } from "react-icons/fa";
import { FaHouseFire } from "react-icons/fa6";
import { FaHouseFloodWater } from "react-icons/fa6";
import { RiEarthquakeFill } from "react-icons/ri";
import { MdLocalPolice } from "react-icons/md";
import { FaCarCrash } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { motion } from "framer-motion";
import { FaLocationPin } from "react-icons/fa6";
import { IoNotifications } from "react-icons/io5";
import { MdOutlineCallMade } from "react-icons/md";
import useStore from "../store";
import UserEmergencyPopup from "../components/UserEmergencyPopup";
import { Link, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { AiFillAlert, AiOutlineSafety } from "react-icons/ai";
import { LiaTimesCircleSolid } from "react-icons/lia";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { red, green } from "@mui/material/colors";
import { AiFillSafetyCertificate } from "react-icons/ai";
import viewedStatusAudio from "../assets/viewed-status.mp3";
import inprogressStatusAudio from "../assets/inprogress-status.mp3";
import resolvedStatusAudio from "../assets/resolved-status.mp3";
import cancelledStatusAudio from "../assets/canceled-status.mp3";
import { ButtonLoader } from "../components/Loader";
import { BiSolidPhoneCall } from "react-icons/bi";

const socket = io(process.env.REACT_APP_API_BASE_URL);

const UserHome = () => {
  const { currentUser } = useStore((state) => ({
    currentUser: state.currentUser,
  }));

  const [openEmergencyPopup, setOpenEmergencyPopup] = useState(false);
  const [isEmergencyTypesVisible, setEmergencyTypesVisible] = useState(false);
  const [selectedEmergencyType, setSelectedEmergencyType] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);
  const [isPingActive, setPingActive] = useState(false);
  const [emergencyStatus, setEmergencyStatus] = useState(null);
  const [emergencyId, setEmergencyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const socketListenersAttached = useRef(false);

  const [currentCoordinates, setCurrentCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });

  // New state to manage watch position
  const [watchPositionId, setWatchPositionId] = useState(null);

  useEffect(() => {
    const storedEmergencyId = localStorage.getItem("emergencyId");
    const storedEmergencyStatus = localStorage.getItem("emergencyStatus");

    if (storedEmergencyId) {
      setEmergencyId(storedEmergencyId);
    }
    if (storedEmergencyStatus) {
      setEmergencyStatus(storedEmergencyStatus);
      setPingActive(
        storedEmergencyStatus === "new" ||
          storedEmergencyStatus === "viewed" ||
          storedEmergencyStatus === "in Progress"
      );
    }

    if (currentUser && !socketListenersAttached.current) {
      socket.emit("get-latest-notif", currentUser._id);

      socket.on("unread-notif-count", (data) => {
        setUnreadCount(data.count);
      });

      let lastPlayedStatus = null; // Track last played sound status

      socket.on("emergency-status-update", (data) => {
        if (
          data.citizenId === currentUser._id &&
          data.emergencyId === emergencyId
        ) {
          console.log("Updating ping status based on:", data.status);

          if (data.status !== lastPlayedStatus) {
            // Only play sound if the status has changed
            lastPlayedStatus = data.status; // Update last played status

            if (data.status === "viewed" && emergencyStatus !== "viewed") {
              playSound(viewedStatusAudio, "viewed");
            } else if (
              data.status === "in Progress" &&
              emergencyStatus !== "in Progress"
            ) {
              playSound(inprogressStatusAudio, "in Progress");
            } else if (
              data.status === "resolved" &&
              emergencyStatus !== "resolved"
            ) {
              playSound(resolvedStatusAudio, "resolved");
            } else if (
              data.status === "cancelled" &&
              emergencyStatus !== "cancelled"
            ) {
              playSound(cancelledStatusAudio, "cancelled");
            }
          }

          // Update localStorage and ping state based on status
          if (data.status === "resolved" || data.status === "cancelled") {
            setLoading(true); // Start loading
            setPingActive(false);
            localStorage.removeItem("emergencyId");
            localStorage.removeItem("emergencyStatus");

            // Set status and reset after 2 seconds
            setTimeout(() => {
              setEmergencyStatus(data.status);
              setTimeout(() => {
                setLoading(false); // Stop loading
                setEmergencyStatus(null); // Reset to default
                window.location.reload(); // Reload the page
              }, 1000); // Loading after setting the resolved status
            }, 2000); // Initial loading for resolved or cancelled

            // Stop location tracking
            if (watchPositionId !== null) {
              navigator.geolocation.clearWatch(watchPositionId);
              setWatchPositionId(null);
            }
          } else {
            setPingActive(true);
            localStorage.setItem("emergencyId", emergencyId);
            localStorage.setItem("emergencyStatus", data.status);
            setEmergencyStatus(data.status);
          }
        }
      });

      // Emit a new emergency alert when the emergencyId is set
      if (currentUser && emergencyId) {
        console.log("Emitting new emergency alert with ID:", emergencyId);
        socket.emit("new-emergency-alert", {
          citizenId: currentUser._id,
          emergencyId: emergencyId,
          latitude: currentCoordinates.latitude,
          longitude: currentCoordinates.longitude,
        });
      }

      socketListenersAttached.current = true;

      return () => {
        socket.off("unread-notif-count");
        socket.off("emergency-status-update");
        socketListenersAttached.current = false;
      };
    }
  }, [currentUser, emergencyId, currentCoordinates, emergencyStatus]);

  // Helper function to play sound with error handling
  const playSound = (audioFile, status) => {
    const audio = new Audio(audioFile);

    // Attempt to play audio and catch any errors
    audio
      .play()
      .then(() => {
        console.log(`Playing ${status} status audio`);
      })
      .catch((error) => {
        console.error(`Error playing ${status} status audio:`, error);
      });
  };

  // Handle geolocation tracking based on emergency status
  useEffect(() => {
    if (
      !navigator.geolocation ||
      !currentUser ||
      !emergencyId ||
      emergencyStatus === "resolved" ||
      emergencyStatus === "cancelled"
    ) {
      return; // Skip if geolocation is not supported or required conditions are not met
    }

    // Geolocation options for higher accuracy and quicker updates
    const options = {
      enableHighAccuracy: true,
      maximumAge: 0, // No caching of previous location
      timeout: 5000, // Timeout for each location request (5 seconds)
    };

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentCoordinates({ latitude, longitude });

        if (currentUser._id && emergencyId) {
          // Emit location updates to the server
          socket.emit("update-location", {
            citizenId: currentUser._id,
            emergencyId,
            latitude,
            longitude,
          });
        } else {
          console.error(
            "Missing currentUser or emergencyId. Location update not sent."
          );
        }
      },
      (error) => console.error("Error getting location:", error),
      options
    );

    // Store the watchId to stop tracking when status is resolved/cancelled
    setWatchPositionId(watchId);

    return () => {
      if (watchId !== null) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [currentUser, emergencyId, emergencyStatus]);

  const handleEmergencyButtonClick = () => {
    setEmergencyTypesVisible(!isEmergencyTypesVisible);
  };

  const handleEmergencyTypeSelect = (type) => {
    setSelectedEmergencyType(type);
    setOpenEmergencyPopup(true);
  };

  const activatePing = () => {
    setPingActive(true);
  };

  // Function to handle updating the emergency status
  const updateEmergencyStatus = async (emergencyId, newStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/server/user-alert/update-status`,
        {
          emergencyId,
          citizenId: currentUser._id, // Include citizenId
          status: newStatus,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        console.log("Emergency status updated successfully:", newStatus);
        return newStatus;
      }
    } catch (error) {
      console.error("Failed to update emergency status:", error);
    }
  };

  const handleStatusChange = async (status, setButtonLoading) => {
    if (currentUser && emergencyId) {
      setButtonLoading(true); // Start button-specific loading
      try {
        const newStatus = await updateEmergencyStatus(emergencyId, status);
        if (newStatus) {
          setEmergencyStatus(newStatus);
          localStorage.setItem("emergencyStatus", newStatus);

          // Reset status after 5 seconds
          setTimeout(() => {
            setPingActive(false);
            localStorage.removeItem("emergencyId");
            localStorage.removeItem("emergencyStatus");
            setEmergencyStatus(null);
          }, 1000);
        }
      } catch (error) {
        console.error("Error updating status:", error);
      } finally {
        setTimeout(() => {
          setButtonLoading(false); // Stop loading after status update
        }, 1000);
      }
    }
  };

  const handleImSafeClick = () =>
    handleStatusChange("resolved", setImSafeLoading);
  const handleCancelClick = () =>
    handleStatusChange("cancelled", setCancelLoading);

  // Component State for Button Loading
  const [imSafeLoading, setImSafeLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const renderEmergencyStatusMessage = () => {
    switch (emergencyStatus) {
      case "new":
        return "Your emergency request is being processed. Please stay calm.";
      case "viewed":
        return "Help is on the move! Someone has seen your request.";
      case "in Progress":
        return "Rescuers are on their way to your location. Please stay where you are";
      case "resolved":
        return "The emergency has been resolved. You are safe now.";
      case "cancelled":
        return "You have canceled the emergency request.";
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  const addressLocation = currentUser.addressLocation?.[0];

  const currentLocation = {
    street: addressLocation?.street || "No street available",
    barangay: addressLocation?.barangay?.name || "No barangay available",
    city: addressLocation?.city?.name || "No city available",
    province: addressLocation?.province?.name || "No province available",
    region: addressLocation?.region?.name || "No region available",
    latitude: addressLocation?.latitude || 0,
    longitude: addressLocation?.longitude || 0,
  };

  const handleClickLocation = () => {
    navigate("/user-location", { state: { currentLocation } });
  };

  const handleClickHotline = () => {
    navigate("/user-contact");
  };

  const openCloseAnimation = {
    open: {
      y: 10,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        duration: 0.1,
      },
    },
    closed: {
      y: 400,
      opacity: 0,
      transition: {
        stiffness: 300,
        damping: 30,
        duration: 0.2,
      },
    },
  };

  return (
    <div className="w-full relative">
      <div className="flex justify-between px-4 pt-6 pb-4  bg-white drop-shadow-sm">
        <div className="flex gap-4 text-gray-600">
          <div className="bg-gray-100 p-1 rounded-full ">
            <img
              src={currentUser.profilePicture}
              className="w-12 h-12 rounded-full object-cover"
              alt="Profile"
            />
          </div>

          <div className="self-center">
            <p className="font-semibold">Welcome 👋</p>
            <p className="text-sm">{currentUser.name}</p>
          </div>
        </div>
        <Link to="/user-notification">
          <div className="self-center bg-blue-50 p-2 rounded-xl relative">
            <IoNotifications size={30} className="text-blue-400" />
            {/* Notification status */}
            {unreadCount > 0 && (
              <div className="absolute top-1 right-1 border border-white h-[1.3rem] w-[1.3rem] rounded-full bg-red-400 flex items-center justify-center">
                <span className="flex items-center text-white text-xs">
                  {unreadCount}
                </span>
              </div>
            )}
          </div>
        </Link>
      </div>

      {/* Emergency text status */}
      <div className="text-center mt-10">
        {!emergencyStatus ? (
          <>
            {/* Emergency default text */}
            <div className="font-semibold text-lg">Emergency help needed?</div>
            <div className="text-gray-500 text-sm">
              Tap in case of emergency
            </div>
          </>
        ) : (
          <div
            className={`font-semibold mx-8 p-2 rounded-xl text-sm ${
              emergencyStatus === "resolved"
                ? "text-green-500 bg-green-500 bg-opacity-15 border border-green-300"
                : emergencyStatus === "cancelled"
                ? "text-gray-500 bg-gray-500 bg-opacity-10 border border-gray-300"
                : emergencyStatus === "in Progress"
                ? "text-orange-500 bg-orange-500 bg-opacity-15 border border-orange-300"
                : "text-red-500 bg-red-500 bg-opacity-15 border border-red-300"
            }`}
          >
            {renderEmergencyStatusMessage()}
          </div>
        )}
      </div>

      {/* Emergency button */}
      <div
        className={`${
          emergencyStatus === "resolved"
            ? "bg-green-100"
            : emergencyStatus === "in Progress"
            ? "bg-orange-100"
            : "bg-red-100"
        } z-[2] p-6 rounded-full mt-6 flex justify-center mx-auto w-48 h-48 relative`}
      >
        <div
          className={`
      ${
        ["new", "viewed", "in Progress"].includes(emergencyStatus)
          ? "cursor-not-allowed"
          : "cursor-pointer"
      } 
      ${
        emergencyStatus === "resolved"
          ? "bg-green-300"
          : emergencyStatus === "in Progress"
          ? "bg-orange-300"
          : "bg-red-300"
      } 
      p-4 rounded-full flex justify-center w-full`}
          onClick={
            !["new", "viewed", "in Progress"].includes(emergencyStatus)
              ? handleEmergencyButtonClick
              : null
          }
        >
          <div
            className={`${
              emergencyStatus === "resolved"
                ? "bg-gradient-to-l from-green-400 to-green-600"
                : emergencyStatus === "in Progress"
                ? "bg-gradient-to-l from-orange-400 to-orange-600"
                : "bg-gradient-to-l from-red-400 to-red-600"
            } flex justify-center w-full rounded-full`}
          >
            <div className="my-auto text-2xl text-white font-bold">
              {emergencyStatus === "resolved" ? (
                <AiFillSafetyCertificate size={35} />
              ) : emergencyStatus === "in Progress" ? (
                <FaAmbulance size={35} />
              ) : emergencyStatus === "viewed" ? (
                <AiFillAlert size={35} />
              ) : (
                <BiSolidPhoneCall size={35} /> // You can use any icon for other statuses or if "new" is not matched
              )}
            </div>
          </div>
        </div>

        {/* Ping animation */}
        {isPingActive && (
          <div
            className={`z-[1] animate-ping absolute inline-flex h-28 w-28 top-10 items-center justify-center rounded-full ${
              emergencyStatus === "resolved"
                ? "bg-green-300"
                : emergencyStatus === "in Progress"
                ? "bg-orange-300"
                : "bg-red-300"
            }`}
          ></div>
        )}
        {loading && (
          <CircularProgress
            size={195}
            sx={{
              color: emergencyStatus === "resolved" ? green[500] : red[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-98px",
              marginLeft: "-98px",
            }}
          />
        )}
      </div>

      {/* Safe and Cancel button*/}
      {emergencyId &&
        (emergencyStatus === "new" ||
          emergencyStatus === "viewed" ||
          emergencyStatus === "in Progress") && (
          <div className="w-full mt-8 flex justify-center gap-4">
            <button
              onClick={handleImSafeClick}
              className="py-2 px-3 bg-red-500 text-white rounded-lg flex gap-2 items-center w-28"
              disabled={imSafeLoading || cancelLoading} // Disable during loading
            >
              {imSafeLoading ? (
                <span className="bg-opacity-60 items-center w-28">
                  <ButtonLoader color="white" />
                </span> // Replace with a spinner or custom loader
              ) : (
                <>
                  <AiOutlineSafety size={18} />
                  I'm Safe
                </>
              )}
            </button>
            <button
              onClick={handleCancelClick}
              className="py-2 px-3 bg-gray-200 text-gray-500 rounded-lg flex gap-2 items-center w-28"
              disabled={cancelLoading || imSafeLoading} // Disable during loading
            >
              {cancelLoading ? (
                <span className="bg-opacity-60 items-center w-28">
                  <ButtonLoader />
                </span> // Replace with a spinner or custom loader
              ) : (
                <>
                  <LiaTimesCircleSolid size={18} />
                  Cancel
                </>
              )}
            </button>
          </div>
        )}

      {/* Emergency hotline */}
      <div
        onClick={handleClickHotline}
        className="bg-gradient-to-l from-red-50 to-red-100 py-2 px-4 mx-8 mt-8 rounded-xl flex justify-between border border-red-200 cursor-pointer"
      >
        <div className="self-center font-semibold text-red-500">
          Emergency Hotline
        </div>
        <div className="self-center text-red-500 rounded-full p-2">
          <MdOutlineCallMade size={22} />
        </div>
      </div>

      {/* Current user location  */}
      <div
        className="bg-white p-4 mx-8 mt-2 rounded-xl cursor-pointer"
        onClick={handleClickLocation}
      >
        <div className="flex gap-4">
          <div className="w-10 flex items-center justify-center text-slate-600">
            <FaStreetView size={30} />
          </div>
          <div className="w-full">
            <p className="text-sm font-semibold text-slate-600">
              Your current Location
            </p>
            <p className="text-xs text-slate-500">
              {currentLocation.street}, {currentLocation.barangay},
              {currentLocation.city}, {currentLocation.province},{" "}
              {currentLocation.region}
            </p>
          </div>
        </div>
      </div>

      {/* Current Coordinates */}
      <div className="bg-white p-2 rounded-lg mx-8 text-sm mt-2 mb-4">
        <p>Display Real-time Current location</p>
        <div className="flex flex-col">
          <div>Latitude: {currentCoordinates.latitude}</div>
          <div>Longitude: {currentCoordinates.longitude}</div>
        </div>
      </div>

      {/* Type of Emergency */}
      <div>
        <div
          onClick={() => setEmergencyTypesVisible(false)}
          className={`fixed max-w-lg mx-auto inset-0 z-[996] bg-black/50 ${
            isEmergencyTypesVisible ? "block" : "hidden"
          }`}
        ></div>

        <motion.div
          variants={openCloseAnimation}
          initial="closed"
          animate={isEmergencyTypesVisible ? "open" : "closed"}
          className="fixed bottom-16 bg-white px-2 py-2 pb-8 rounded-t-2xl z-[997] max-w-lg"
        >
          <div className="flex justify-between p-4 font-semibold text-slate-500 ite">
            <p>Select type of emergency</p>
            <div className="bg-gray-50 p-2 rounded-full self-center cursor-pointer">
              <MdOutlineClose size={20} onClick={handleEmergencyButtonClick} />
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-4">
            <div
              className="w-[9rem] items-start p-4 bg-gray-100 rounded-xl cursor-pointer"
              onClick={() => handleEmergencyTypeSelect("Health")}
            >
              <FaAmbulance className="mx-auto text-green-400" size={25} />
              <p className="mt-2 font-semibold text-slate-500 text-center text-sm">
                Health
              </p>
            </div>
            <div
              className="w-[9rem] items-start p-4 bg-gray-100 rounded-xl cursor-pointer"
              onClick={() => handleEmergencyTypeSelect("Fire")}
            >
              <FaHouseFire className="mx-auto text-red-400" size={25} />
              <p className="mt-2 font-semibold text-slate-500 text-center text-sm">
                Fire
              </p>
            </div>
            <div
              className="w-[9rem] items-start p-4 bg-gray-100 rounded-xl cursor-pointer"
              onClick={() => handleEmergencyTypeSelect("Flood")}
            >
              <FaHouseFloodWater className="mx-auto text-blue-400" size={25} />
              <p className="mt-2 font-semibold text-slate-500 text-center text-sm">
                Flood
              </p>
            </div>
            <div
              className="w-[9rem] items-start p-4 bg-gray-100 rounded-xl cursor-pointer"
              onClick={() => handleEmergencyTypeSelect("Earthquake")}
            >
              <RiEarthquakeFill className="mx-auto text-stone-400" size={25} />
              <p className="mt-2 mx-auto font-semibold text-slate-500 text-center text-sm">
                Earthquake
              </p>
            </div>
            <div
              className="w-[9rem] items-start p-4 bg-gray-100 rounded-xl cursor-pointer"
              onClick={() => handleEmergencyTypeSelect("Crime")}
            >
              <MdLocalPolice className="mx-auto text-yellow-400" size={25} />
              <p className="mt-2 font-semibold text-slate-500 text-center text-sm">
                Crime
              </p>
            </div>
            <div
              className="w-[9rem] items-start p-4 bg-gray-100 rounded-xl cursor-pointer"
              onClick={() => handleEmergencyTypeSelect("Traffic Accidents")}
            >
              <FaCarCrash className="mx-auto text-orange-400" size={25} />
              <p className="mt-2 font-semibold text-slate-500 text-center text-sm">
                Traffic Accidents
              </p>
            </div>
          </div>
        </motion.div>
      </div>
      <UserEmergencyPopup
        openEmergencyPopup={openEmergencyPopup}
        setOpenEmergencyPopup={setOpenEmergencyPopup}
        setEmergencyTypesVisible={setEmergencyTypesVisible}
        emergencyType={selectedEmergencyType}
        activatePing={activatePing}
        setEmergencyId={setEmergencyId}
      />
    </div>
  );
};

export default UserHome;
