import React, { useEffect, useRef, useState } from "react";
import NewPrompt from "../../components/AIChatBot/NewPrompt";
import MarkdownRenderer from "../../components/AIChatBot/MarkDownRenderer";
import { IKImage } from "imagekitio-react";
import { RiRobot2Line } from "react-icons/ri";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
import { FaArrowDown } from "react-icons/fa";
import { DefaultLoader } from "../../components/Loader";

const AIChatPage = () => {
  const endRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const { pathname } = useLocation();
  const chatId = pathname.split("/").pop();
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery({
    queryKey: ["chat", chatId],
    queryFn: () =>
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/server/chat/userchat/${chatId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      ).then((res) => res.json()),
    refetchInterval: 100, // Optional: Polling to keep data up-to-date
  });

  useEffect(() => {
    if (data) {
      setMessages(data.history || []);

      if (!isFirstMessageSent && data.history.length === 1) {
        setIsFirstMessageSent(true);
        const firstMessage = data.history[0].parts[0].text;
        handleSendMessage({ text: firstMessage, isUser: false });
      }
    }
  }, [data, isFirstMessageSent]);

  const handleSendMessage = async ({
    text,
    answer,
    imageURL,
    isUser,
    isPartial,
  }) => {
    setMessages((prevMessages) => {
      const newMessage = {
        id: Date.now(),
        parts: [{ text: text || "" }],
        img: imageURL,
        role: isUser ? "user" : "model",
      };

      if (isUser) {
        queryClient.invalidateQueries(["chat", chatId]); // Refetch when a new user message is sent
        return [
          ...prevMessages,
          newMessage,
          { id: Date.now() + 1, parts: [{ text: "" }], role: "model" },
        ];
      }

      const lastMessageIndex = prevMessages.length - 1;
      const lastMessage = prevMessages[lastMessageIndex];

      if (isPartial && lastMessage && lastMessage.role === "model") {
        lastMessage.parts[0].text = answer;
        return [...prevMessages];
      }

      if (!isPartial && lastMessage && lastMessage.role === "model") {
        lastMessage.parts[0].text = answer;
        return [...prevMessages];
      }

      return [...prevMessages, newMessage];
    });
  };

  useEffect(() => {
    if (endRef.current) {
      setTimeout(() => {
        endRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [messages]);

  const handleScroll = debounce(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        chatContainerRef.current;
      setShowScrollToBottom(scrollTop < scrollHeight - clientHeight - 50); // Adjust threshold if needed
    }
  }, 100); // Adjust debounce delay if needed

  const scrollToBottom = () => {
    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="h-[calc(100vh-170px)] flex flex-col relative  w-full">
      <div
        ref={chatContainerRef}
        className="flex-1 flex flex-col overflow-y-auto  relative"
        onScroll={handleScroll} // Handle scroll event with debounce
      >
        <div className="w-full flex flex-col gap-4 pl-8 pr-4 py-4 mb-8">
          {isLoading ? (
            <div>
              <DefaultLoader />
            </div>
          ) : error ? (
            `Something went wrong! ${error.message}`
          ) : (
            messages.map((message, i) => (
              <div
                key={i}
                className={`relative flex ${
                  message.role === "user" ? "justify-end" : "justify-start ml-8"
                }`}
              >
                {message.role === "model" && (
                  <div className="absolute -top-2 -left-14 text-white mt-2 ml-2 bg-blue-500  p-2 rounded-xl">
                    <RiRobot2Line size={24} />
                  </div>
                )}
                <div>
                  {message.img && (
                    <div className="mb-3 flex justify-end">
                      <IKImage
                        urlEndpoint={process.env.REACT_APP_IMAGE_KIT_ENDPOINT}
                        path={message.img}
                        width={300}
                        transformation={[{ width: 300 }]}
                        className="rounded-lg"
                      />
                    </div>
                  )}
                  <div
                    className={`px-4 pt-2 pb-[0.01rem] text-sm md:text-base rounded-2xl ${
                      message.role === "user"
                        ? !message.parts[0].text
                          ? "bg-none"
                          : "bg-blue-500 text-white whitespace-pre-wrap"
                        : "bg-gray-200 "
                    }`}
                  >
                    <MarkdownRenderer>{message.parts[0].text}</MarkdownRenderer>
                  </div>
                </div>
              </div>
            ))
          )}

          <div ref={endRef} className="py-8" />
        </div>
      </div>
      <div className="relative">
        {showScrollToBottom && (
          <button
            className="absolute bottom-20 left-1/2 transform -translate-x-1/2 bg-white dark:bg-secondary-surface-color text-gray-600 dark:text-text_dark p-2.5 rounded-full"
            onClick={scrollToBottom}
          >
            <FaArrowDown />
          </button>
        )}
        <div className="absolute -bottom-6  w-full">
          <NewPrompt onSendMessage={handleSendMessage} data={data} />
        </div>
      </div>
    </div>
  );
};

export default AIChatPage;
